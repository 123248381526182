div#footer {
  position: fixed;
  bottom: 0px;
  padding-top: 10px;
  border-top: 1px solid gray;
  width: 100%;
  z-index: 1;
  background-color: #f5f5f5;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
}

html,
body,
#app,
#app > div {
  height: 100%;
}
